
	/* eslint-disable @typescript-eslint/no-magic-numbers */
	import { Component, Vue } from "vue-property-decorator";

	import { today } from "@common/date";
	import TitleModal from "@/components/modal/TitleModal.vue";
	import Base from "../Base";
	import FilterModal, { toIndexQuery } from "./modal/FilterModal.vue";
	import ExcelModal from "./modal/ExcelModal.vue";
	import Kiosk from "./components/Kiosk.vue";
	import Summary from "./components/Summary.vue";
	import { cache as DetailCache } from "./Detail.vue";

	import type { Query } from "./modal/FilterModal.vue";
	import type { ReportIndexModel, ReportIndexBundle, ReportModel } from "@/models/Report";
	import type { TagModel } from "@common/models/kiosk";
	import type { NavigationGuardNext, Route } from "vue-router";

	let cache: { query: Query, state?: { scroll: number, data: ReportIndexBundle, time: string } } | null = null;

	export function resetState(): void {
		if(cache) cache.state = undefined;
	}

	export function resetCache(): void {
		cache = null;
		DetailCache.query = null;
	}

	@Component({ components: { Kiosk, Summary, FilterModal, ExcelModal, TitleModal } })
	export default class extends Base {
		$refs!: {
			modal: TitleModal,
			filter: FilterModal,
			excel: ExcelModal,
		};

		protected data: ReportIndexBundle | null = null;
		protected summary: ReportModel[] = [];
		protected query: Query = {
			start: today(),
            end: today().addDays(1).addSeconds(-1),
			mode: "tag",
			tag: [],
			id: [],
			init: 0,
			city: null,
			town: null,
		};
		protected tags: TagModel[] = [];

		protected get rangeString(): string {
			let start = this.query.start.format();
			let end = this.query.end.format();
			let result = start;
			if(end != start) result += ' 到 ' + end;
			return result;
		}

		protected get active(): ReportIndexModel[] {
			return this.data?.Kiosks.filter(d => d.IsActive) ?? [];
		}

		protected get inactive(): ReportIndexModel[] {
			return this.data?.Kiosks.filter(d => !d.IsActive) ?? [];
		}

		beforeMount(): void {
			post<TagModel[]>('/api/tag').then(v => this.tags = v);
			let scroll = 0;
			if(cache) {
				this.query = cache.query;
				if(cache.state) {
					scroll = cache.state.scroll;
					this.data = cache.state.data;
					this.summary = this.data.Kiosks.map(d => d.Report);
					this.time = cache.state.time;
					Vue.nextTick(() => this.setScroll(scroll));
				} else {
					this.load();
				}
				resetCache();
			} else {
				this.load();
			}
		}

		private setScroll(scroll: number) {
			// Safari 裡面會需要一個難以預測的延遲才能正確還原捲動，
			// 因此這裡的辦法是不斷嘗試直到還原成功為止
			let main = document.querySelector('main')!;
			if(main.scrollTop == scroll) return;
			main.scrollTop = scroll;
			setTimeout(() => this.setScroll(scroll), 50);
		}

		beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext): void {
			if(to.path.startsWith('/report/detail')) {
				DetailCache.query = {
					start: this.query.start,
					end: this.query.end,
					init: this.query.init,
				};
				cache = {
					query: this.query,
					state: {
						time: this.time!,
						data: this.data!,
						scroll: document.querySelector('main')!.scrollTop,
					},
				};
			} else {
				resetCache();
			}
			next();
		}

		protected async showFilter(): Promise<void> {
			if(await this.$refs.filter.show()) await this.load();
		}

		protected edit(k: ReportIndexModel): void {
			var m = this.$refs.modal;
			m.model = k;
			m.show();
		}

		private async load() {
			this.data = null;
			this.summary = [];
			try {
				this.data = await post<ReportIndexBundle>("/api/Report", toIndexQuery(this.query));
				this.summary = this.data.Kiosks.map(d => d.Report);
				this.update = new Date();
			} catch(e) {
				this.data = null;
				throw e;
			}
		}

		public add(): void {
			this.$router.push('/device/add/');
		}

		protected excel(): void {
			this.$refs.excel.show();
		}
	}
