
	import { Component, Prop, Vue } from "vue-property-decorator";

	import { Tooltip } from 'bootstrap';

	const TOOLTIP_TIME = 3000;

	@Component
	export default class extends Vue {
		@Prop() public title?: string;

		private tooltip: Tooltip = null!;

		mounted(): void {
			this.tooltip = Tooltip.getOrCreateInstance(this.$el);
		}

		beforeDestroy(): void {
			// 在這邊直接立刻 dispose 掉的話，
			// tooltip 隱藏的動畫結束後的 callback 裡面會觸發錯誤，
			// 解法就是先隱藏然後延後 dispose。
			this.tooltip.hide();
			setTimeout(() => this.tooltip.dispose(), TOOLTIP_TIME);
		}
	}
