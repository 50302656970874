var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as bootstrap from 'bootstrap';
import { Component, Prop, Vue } from 'vue-property-decorator';
export function toFilterQuery(query, options = {}) {
    if (query.mode == 'id')
        options.Ids = query.id;
    else
        options.Tags = query.tag;
    options.CityId = query.city == "null" ? null : query.city;
    options.TownId = query.town == "null" ? null : query.town;
    return options;
}
export function toIndexQuery(query) {
    let options = {
        Start: query.start.toIsoTime(),
        End: query.end.toIsoTime(),
    };
    return toFilterQuery(query, options);
}
let default_1 = class default_1 extends Vue {
    constructor() {
        super(...arguments);
        this.value = false;
        this.options = [];
        this.cityOptions = null;
        this.device = null;
        this.temp = this.query;
    }
    created() {
        post("/api/Merchant/Town").then(v => this.cityOptions = v);
    }
    mounted() {
        this.modal = new bootstrap.Modal(this.$el);
        post("/api/device").then(v => this.options = v);
    }
    get towns() {
        var _a;
        if (!this.temp.city || this.temp.city == "null" || !this.cityOptions)
            return [];
        return ((_a = this.cityOptions.find(c => c.Id == this.temp.city)) === null || _a === void 0 ? void 0 : _a.Towns) || [];
    }
    show() {
        this.value = false;
        return new Promise(resolve => {
            this.copy();
            this.modal.show();
            this.$el.addEventListener('hidden.bs.modal', () => resolve(this.value), { once: true });
        });
    }
    get unselected() {
        return this.options.filter(k => !this.temp.id.includes(k.KioskId));
    }
    onRange(e) {
        if (e.length < 2)
            return;
        if (!e[1])
            e[1] = e[0];
        [this.temp.start, this.temp.end] = e;
    }
    onOption() {
        if (this.device)
            this.temp.id.push(this.device);
        this.device = null;
    }
    copy() {
        Vue.set(this, "temp", {
            start: this.query.start,
            end: this.query.end,
            mode: this.query.mode,
            init: this.query.init,
            tag: this.query.tag.concat(),
            id: this.query.id.concat(),
            city: this.query.city,
            town: this.query.town,
        });
        this.$refs.range.reset(this.query.init);
    }
    getKioskName(c) {
        let k = this.options.find(e => e.KioskId == c);
        if (!k)
            return "";
        return k.Name;
    }
    getKioskCode(c) {
        let k = this.options.find(e => e.KioskId == c);
        if (!k)
            return "";
        return k.DisplayCode;
    }
    remove(c) {
        this.temp.id.splice(this.temp.id.indexOf(c), 1);
    }
    ok() {
        Object.assign(this.query, this.temp);
        this.query.init = this.$refs.range.active;
        this.value = true;
    }
    reset() {
        this.temp.start = new Date();
        this.temp.end = new Date();
        this.temp.mode = "tag";
        this.temp.tag = [];
        this.temp.id = [];
        this.temp.city = null;
        this.temp.town = null;
        this.$refs.range.reset(this.temp.init = 0);
    }
    btnClass(o) {
        return this.temp.tag.includes(o.Id) ? "btn-secondary" : "btn-light";
    }
    toggle(o) {
        let t = o.Id, i = this.temp.tag.indexOf(t);
        if (i >= 0)
            this.temp.tag.splice(i, 1);
        else
            this.temp.tag.push(t);
    }
};
__decorate([
    Prop()
], default_1.prototype, "tags", void 0);
__decorate([
    Prop()
], default_1.prototype, "query", void 0);
default_1 = __decorate([
    Component
], default_1);
export default default_1;
