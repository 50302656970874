
	import { Component, Prop, Vue } from "vue-property-decorator";

	export interface StatisticsItem {
		title: string;
		coin: number;
		leapa: number;
	}

	const H = 100;
	const G = 100;

	@Component
	export default class extends Vue {
		@Prop(String) public title!: string;
		@Prop(Boolean) public go!: boolean;

		@Prop() public data!: StatisticsItem[];

		protected get max(): number {
			if(!this.data) return 0;
			return Math.max(...this.data.map(item => item.leapa), ...this.data.map(item => item.coin));
		}

		protected coinStyle(coin: number): string {
			if(this.max == 0) return `width:0`;
			return `width:${coin / this.max * H}%;`;
		}

		protected leapaStyle(leapa: number): string {
			if(this.max == 0) return `width:0`;
			return `width:${leapa / this.max * G}%;`;
		}
	}
