import type { RouteConfig } from 'vue-router';

/////////////////////////////////////
// 支付申請人功能

const routes: Array<RouteConfig> = [

	{
		path: '/merchant',
		component: () => import(/* webpackChunkName: "merchant" */ '@/views/merchant/Index.vue'),
		meta: {
			root: true,
		},
	},
	{
		path: '/merchant/verify/:id',
		props: true,
		component: () => import(/* webpackChunkName: "merchant" */ '@/views/merchant/apply/Verify.vue'),
		meta: {
			anonymous: true,
		},
	},
	{
		path: '/merchant/edit',
		component: () => import(/* webpackChunkName: "merchant" */ '@/views/merchant/apply/Edit.vue'),
	},
	{
		path: '/merchant/outlet/create',
		component: () => import(/* webpackChunkName: "merchant" */ '@/views/merchant/outlet/Create.vue'),
	},
	{
		path: '/merchant/outlet/edit/:id',
		props: true,
		component: () => import(/* webpackChunkName: "merchant" */ '@/views/merchant/outlet/Edit.vue'),
	},
	{
		path: '/merchant/device/index/:id',
		props: true,
		component: () => import(/* webpackChunkName: "merchant" */ '@/views/merchant/device/Index.vue'),
	},
	{
		path: '/merchant/device/match/:id',
		props: true,
		component: () => import(/* webpackChunkName: "merchant" */ '@/views/merchant/device/Match.vue'),
	},
];

export default routes;
