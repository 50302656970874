
	import { Component, Prop, Vue } from "vue-property-decorator";
	import type { ReportModel } from "@/models/Report";

	@Component
	export default class extends Vue {
		@Prop() protected data!: ReportModel[];

		protected get leapa(): string {
			let result = 0;
			for(let r of this.data) result += r.Leapa;
			return result.toComma();
		}

		protected get coin(): string {
			let result = 0;
			for(let r of this.data) result += r.Coins;
			return result.toComma();
		}
	}
