var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from "vue-property-decorator";
import { format, pad } from "@common/date";
function isZero(date) {
    const [h, m, s] = [date.getHours(), date.getMinutes(), date.getSeconds()];
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    return h == 0 && m == 0 && s == 0 || h == 23 && m == 59 && s == 59;
}
function minute(date) {
    return format(date) + " " + pad(date.getHours()) + ":" + pad(date.getMinutes());
}
let default_1 = class default_1 extends Vue {
    get range() {
        if (isZero(this.start) && isZero(this.end)) {
            if (this.start.datePart().getTime() == this.end.datePart().getTime()) {
                return format(this.start);
            }
            else {
                return format(this.start) + " - " + format(this.end);
            }
        }
        else {
            return minute(this.start) + " - " + minute(this.end);
        }
    }
};
__decorate([
    Prop()
], default_1.prototype, "start", void 0);
__decorate([
    Prop()
], default_1.prototype, "end", void 0);
default_1 = __decorate([
    Component
], default_1);
export default default_1;
