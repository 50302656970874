
	import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
	import * as bootstrap from 'bootstrap';
	import Teleport from 'vue2-teleport';
	import CheckboxBase from '../CheckboxBase.vue';
	import Calender from './Calender.vue';

	@Component({ components: { Teleport, Calender, CheckboxBase } })
	export default class DateTimeRangePicker extends Vue {

		private modal!: bootstrap.Modal;
		protected open: boolean = false;
		protected time: boolean = false;
		protected ok: boolean = false;

		@Prop() public value!: [Date, Date];
		@Prop(Boolean) public hideTime?: boolean;

		@Watch('time') onTimeModeChanged(v: boolean): void {
			if(v) return;
			this.value[0] = this.value[0]?.datePart();
			this.value[1] = this.value[1]?.datePart().addDays(1).addMilliseconds(-1);
		}

		mounted(): void {
			this.modal = new bootstrap.Modal(this.$refs.el, { backdrop: 'static' });
		}

		public show(): Promise<boolean> {
			this.open = true;
			this.ok = false;
			return new Promise(resolve => {
				this.$refs.el.addEventListener('hide.bs.modal', () => resolve(this.ok), { once: true });
				this.modal.show();
			});
		}

		public enter(): void {
			this.open = false;
			this.ok = true;
			this.modal.hide();
		}
	}
