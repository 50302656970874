
	import { Component, Prop, Vue } from "vue-property-decorator";

	@Component
	export default class extends Vue {
		@Prop() bodyClass: unknown;

		protected get hasHeader(): boolean {
			return Boolean(this.$scopedSlots.header);
		}
	}
