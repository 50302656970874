import { settings } from "@common/communication";
import router from "@/router";
import store from "@/store";

import type { LoginResult } from "@/models/Login";

export function login(result: LoginResult, username: string | null): void {
	store.commit("setAccessToken", result);
	if(username) {
		localStorage.setItem("username", username);
	} else {
		localStorage.removeItem("username");
	}
}

export function logout(): void {
	localStorage.removeItem("token");
	store.commit("setAccessToken", null);
	router.replace("/login");
}

settings.logout = logout;
