
	import { Component, Prop, Vue } from "vue-property-decorator";

	@Component
	export default class extends Vue {
		@Prop() public classes!: unknown;
		@Prop() public value?: boolean;

		public get attrs(): object {
			return Object.assign({}, this.$attrs, { id: 'ipt' + this._uid });
		}
	}
