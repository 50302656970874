

	import { Component, Prop } from "vue-property-decorator";
	import LayoutBase from "@common/base/LayoutBase";
	import NavBarLayout from "@common/base/NavBarLayout.vue";

	@Component({
		components: { NavBarLayout },
	})
	export default class extends LayoutBase {
		@Prop({ default: 'col py-3 px-4' }) headerClass!: string;
		@Prop({ default: '' }) bodyClass!: string;
		@Prop(String) backUrl?: string;
		@Prop({ default: true }) context?: unknown;
	}
