
declare global {
	const jsInterface: {
		toWifi(): void;
		download(url: string, filename: string): void;
		share(url: string, text?: string, title?: string): void;
	};
}

export function share(url: string, text: string | undefined, title: string | undefined): void {
	if('share' in navigator) navigator.share({ url, text, title });
	else jsInterface.share(url, text, title);
}
