
	/* eslint-disable */
	import { Component, Prop, Vue } from "vue-property-decorator";

	import * as bootstrap from 'bootstrap';

	interface Wheel {
		type: string;
		translateY: number;
		anim: boolean;
		transitionTime: string;
		data: number[];
	}

	@Component
	export default class extends Vue {
		@Prop({ type: String, default: '' }) public value!: string;
		@Prop({ type: String, default: '' }) public min!: string;
		@Prop({ type: String, default: '' }) public max!: string;
		@Prop({ type: String, default: '' }) public placeholder!: string;
		@Prop({ type: [String, Object, Array], default: '' }) public inputClass!: string;
		@Prop({ type: [String, Object, Array], default: '' }) public inputStyle!: string;
		@Prop({ default: () => new Date() }) public init!: Date;

		protected selectValue = '';
		protected wheels: Wheel[] = [
			{
				type: 'year',
				translateY: 0,
				anim: false,
				transitionTime: '700ms',
				data: []
			},
			{
				type: 'month',
				translateY: 0,
				anim: false,
				transitionTime: '700ms',
				data: []
			},
			{
				type: 'day',
				translateY: 0,
				anim: false,
				transitionTime: '700ms',
				data: []
			}
		];
		protected startY = 0;
		protected moveY = 0;
		protected oldMoveY = 0;
		protected moveEndY = 0;
		protected offsetDistance = 0;
		protected offset = 0;
		protected oversizeBorder = 0;
		protected startTime = 0;
		protected liHeight = 40;
		protected minDate = '';
		protected maxDate = '';
		protected initDate = '';
		protected clickFlag: boolean = false;
		protected activeClick = {
			wheelIndex: -100,
			itemIndex: -100,
		};
		protected activeWheelIndex = 0;
		protected windowHeight = document.documentElement.clientHeight || 300;

		private modal!: bootstrap.Modal;

		mounted(): void {
			this.modal = new bootstrap.Modal(this.$refs.mdl as HTMLDivElement);
			this.selectValue = this.value;
			this.initSetting();
			this.initOption();
			this.initListener();
		}

		protected pad(n: number) {
			return (n < 10 ? '0' : '') + n;
		}
		protected getOpacity(wheelIndex: number, itemIndex: number): number {
			return wheelIndex == this.activeClick.wheelIndex && itemIndex == this.activeClick.itemIndex ? 1 : 0;
		}

		protected initListener() {
			var _this = this;
			window.addEventListener("resize", function() {
				_this.windowHeight = document.documentElement.clientHeight || 300;
			}, false);
		}
		protected initSetting(): void {
			let def = this.init;
			let max = new Date(Date.parse(this.max));
			let min = new Date(Date.parse(this.min));
			if(def > max) def = max;
			if(def < min) def = min;

			this.initDate = this.value && this.checkIsFormatStr(this.value) ? this.value : this.getDateStr(def);
			this.maxDate = this.max && this.checkIsFormatStr(this.max) ? this.max : this.getDateStr(def, 2);
			this.minDate = this.min && this.checkIsFormatStr(this.min) ? this.min : this.getDateStr(def, -10);
		}
		protected initOption(): void {
			var maxDateObj = this.getDateStrObj(this.maxDate);
			var minDateObj = this.getDateStrObj(this.minDate);
			var initDateObj = this.getDateStrObj(this.initDate);

			for(var i = minDateObj.year; i <= maxDateObj.year; i++) {
				this.wheels[0].data.push(i);
			}
			for(var j = 1; j <= 12; j++) {
				this.wheels[1].data.push(j);
			}
			for(var k = 1; k <= this.calcDays(initDateObj.year, initDateObj.month); k++) {
				this.wheels[2].data.push(k);
			}
			this.locateWheelByVal(this.initDate);
		}
		protected locateWheelByVal(dateString: string): void {
			var minDateObj = this.getDateStrObj(this.minDate);
			var dateObj = this.getDateStrObj(dateString);
			// 定位显示初始值
			this.wheels[0].translateY = this.getDistanceByIndex(dateObj.year - minDateObj.year);
			this.wheels[1].translateY = this.getDistanceByIndex(dateObj.month - 1);
			this.wheels[2].translateY = this.getDistanceByIndex(dateObj.day - 1);
		}
		protected getDateStrObj(dateString: string) {
			// 特殊情况的处理
			var tempArr = dateString.split('-');
			return {
				year: ~~tempArr[0],
				month: ~~tempArr[1],
				day: ~~tempArr[2]
			}
		}
		protected addPrefix(num: number) {
			return num < 10 ? '0' + num : num;
		}
		protected getDateStr(dateObj: Date, offsetYear?: number, offsetMonth?: number, offsetDay?: number) {
			var tempArr = [];
			tempArr.push(dateObj.getFullYear() + (offsetYear || 0));
			tempArr.push(this.addPrefix(dateObj.getMonth() + 1 + (offsetMonth || 0)));
			tempArr.push(this.addPrefix(dateObj.getDate() + (offsetDay || 0)));
			return tempArr.join('-');
		}
		protected checkIsFormatStr(dateString: string) {
			if(dateString && typeof (dateString) == 'string') {
				var tempArr = dateString.split('-');
				if(tempArr.length > 2) {
					var year = ~~tempArr[0],
						month = ~~tempArr[1],
						day = ~~tempArr[2];
					if((year > 0 && year < 10000) && (month >= 1 && month <= 12) && (day >= 1 && day <= this.calcDays(year, month))) {
						return true;
					}
				}
			}
			console.warn('日期"' + dateString + '"不符合"yyyy-mm-dd格式"，或不存该日期，已替换为默认值。');
			return false;
		}
		protected getDistanceByIndex(index: number) {
			return (2 - index) * this.liHeight;
		}
		protected getIndexByDistance(translateY: number) {
			return Math.floor(-translateY / this.liHeight) + 2;
		}
		protected getWheelData(wheelIndex: number) {
			var dataIndex = this.getIndexByDistance(this.wheels[wheelIndex].translateY);
			dataIndex = dataIndex < 0 ? 0 : dataIndex;
			dataIndex = dataIndex >= this.wheels[wheelIndex].data.length ? this.wheels[wheelIndex].data.length - 1 : dataIndex;
			return this.wheels[wheelIndex].data[dataIndex];
		}
		//求月份最大天数
		protected calcDays(year: number, month: number) {
			// 传进来的month值是已经加1的，而下面的技巧中刚好Month也需要+1
			return new Date(year, month, 0).getDate();
		}
		protected fixPosition(distance: number) {
			//修正位置
			return Math.round(distance / this.liHeight) * this.liHeight;
		}
		protected checkIsOverBorder(curWheelObj: Wheel) {
			//反弹
			var _this = this;
			this.oversizeBorder = -(curWheelObj.data.length - 3) * this.liHeight;
			if(curWheelObj.translateY > 2 * this.liHeight) {
				setTimeout(function() {
					curWheelObj.transitionTime = '700ms';
					curWheelObj.translateY = 2 * _this.liHeight; // 需要增加动画
				}, 100);

			} else if(curWheelObj.translateY < this.oversizeBorder) {
				setTimeout(function() {
					curWheelObj.transitionTime = '700ms';
					curWheelObj.translateY = _this.oversizeBorder;
				}, 100);
			}
		}
		protected updateDays() {
			var newMonthDaysNum = this.calcDays(this.getWheelData(0), this.getWheelData(1));
			if(newMonthDaysNum > 0 && this.wheels[2].data.length != newMonthDaysNum) {
				var tempArr = [];
				for(var k = 1; k <= newMonthDaysNum; k++) {
					tempArr.push(k);
				}
				this.wheels[2].data = tempArr;
				this.checkIsOverBorder(this.wheels[2]);
			}
		}
		protected handleTouch(e: any, index?: number) {
			e = e || window.event;
			var curWheelObj = typeof (index) == 'number' ? this.wheels[index] : this.wheels[this.activeWheelIndex];
			switch(e.type) {
				case 'touchstart':
				case 'mousedown':
					if(e.type == 'touchstart') {
						this.startY = e.touches[0].clientY;
					} else {
						// mousedown
						this.startY = e.clientY;
						this.activeWheelIndex = index!;
						this.clickFlag = true;
						e.preventDefault();
					}
					curWheelObj.anim = false;
					this.oldMoveY = this.startY;
					this.startTime = (new Date()).getTime();
					break;

				case 'touchend':
				case 'mouseup':
					if(e.type == 'touchend') {
						this.moveEndY = e.changedTouches[0].clientY;
					} else {
						// mousedown
						this.moveEndY = e.clientY;
						this.clickFlag = false;
					}

					this.offsetDistance = this.moveEndY - this.startY;
					curWheelObj.anim = true;
					curWheelObj.translateY = this.fixPosition(curWheelObj.translateY + this.offset);
					var offsetTime = (new Date()).getTime() - this.startTime;
					var scrollSpeed = this.offsetDistance / offsetTime;
					var tempTime = Math.abs(Math.floor(scrollSpeed * 1000));
					curWheelObj.transitionTime = '700ms';
					if(Math.abs(scrollSpeed) > 0.3) {
						curWheelObj.transitionTime = tempTime > 700 ? (tempTime + 'ms') : '700ms';
						curWheelObj.translateY = this.fixPosition(curWheelObj.translateY + scrollSpeed * 250);
					}
					this.checkIsOverBorder(curWheelObj);
					this.clearHover();
					break;

				case 'mousemove':
				case 'touchmove':
					e.preventDefault();
					if(e.type == 'mousemove' && !this.clickFlag) {
						return false;
					}
					this.moveY = e.type == 'touchmove' ? e.touches[0].clientY : e.clientY;
					this.offset = this.moveY - this.oldMoveY;
					curWheelObj.translateY += this.offset;
					this.oldMoveY = this.moveY;
					break;

				case 'mousewheel':
					curWheelObj.anim = true;
					curWheelObj.translateY = this.fixPosition(curWheelObj.translateY + Math.floor((e.wheelDelta || e.detail) * 0.3));
					this.oversizeBorder = -(curWheelObj.data.length - 3) * this.liHeight;
					this.checkIsOverBorder(curWheelObj);
					break;
			}
			// 切换年月的时候，更新天数
			if(index! <= 1) {
				this.updateDays();
			}
		}
		protected handleSingleClick(e: Event, wheelIndex: number, itemIndex: number) {
			if(Math.abs(this.offsetDistance) < 30) {
				this.wheels[wheelIndex].translateY = this.getDistanceByIndex(itemIndex);
			}
		}
		protected hoverClass(e: Event, wheelIndex: number, itemIndex: number) {
			this.activeClick.wheelIndex = wheelIndex;
			this.activeClick.itemIndex = itemIndex;
		}
		protected clearHover() {
			this.activeClick.wheelIndex = -100;
			this.activeClick.itemIndex = -100;
		}
		protected getSelectData() {
			var _this = this;
			var tempArr: (string | number)[] = [];
			this.wheels.forEach(function(item, wheelIndex) {
				tempArr.push(_this.addPrefix(_this.getWheelData(wheelIndex)));
			});
			this.selectValue = tempArr.join('-');
			this.$emit('input', this.selectValue);
			this.hidePanel();
		}
		protected hidePanel() {
			this.clearHover();
			this.modal.hide();
			// 关闭后 复位已选中的值
			if(this.selectValue) {
				this.locateWheelByVal(this.selectValue);
			}
		}
		protected showPanel(): void {
			if(this.$attrs.disabled || this.$attrs.readonly) return;
			this.clearHover();
			this.modal.show();
		}
	}

