
	import { Component, Prop, Vue } from "vue-property-decorator";
	import ImageUpload from "./ImageUploader.vue";

	import type { UploadModel } from "@common/models";

	let _pictureUploaderId = 0;

	@Component({ components: { ImageUpload } })
	export default class extends Vue {
		@Prop(Number) public size?: number;

		@Prop({
			type: Array,
			default: () => [],
		}) public value!: UploadModel[];

		protected pid: number = _pictureUploaderId++;
		protected wait: boolean = false; // 控制動畫顯示

		protected add(data: UploadModel): void {
			Vue.set(this.value, 0, data);
			this.$emit('input', this.value.concat());
		}
	}
