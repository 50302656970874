
	import { Component, Vue } from "vue-property-decorator";

	const TOAST_TIMER = 2000;

	@Component
	export default class extends Vue {
		$refs!: {
			toast: HTMLDivElement;
		};

		public show(): void {
			this.$refs.toast.classList.add("show");
			setTimeout(() => this.$refs.toast.classList.remove("show"), TOAST_TIMER);
		}
	}
