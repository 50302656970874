
	/* eslint-disable @typescript-eslint/no-magic-numbers */

	import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
	import InputMasked from '@aymkdn/vue-input-masked';

	const YEARS = 20;

	@Component({ components: { InputMasked } })
	export default class Calender extends Vue {
		$refs!: {
			date: InputMasked;
			time: InputMasked;
		}

		@Prop() public value!: Date;
		@Prop() public other!: Date;
		@Prop() public timeMode!: boolean;
		@Prop() public label!: string;
		@Prop(Boolean) public isStart!: boolean;

		private tempDate: string = "";
		private tempTime: string = "";
		private displayDate: Date = new Date().datePart();

		mounted(): void {
			if(this.value) this.onValueChanged(this.value);
			this.$refs.date.$el.addEventListener('blur', () => {
				if(!this.dateChange()) this.tempDate = this.date;
			});
			this.$refs.time.$el.addEventListener('blur', () => {
				if(!this.timeChange()) this.tempTime = this.time;
			});
		}

		@Watch('value') onValueChanged(v: Date): void {
			this.displayDate = v.clone();
			this.tempDate = this.date;
			this.tempTime = this.time;
		}

		public get yearOptions(): number[] {
			let start = new Date().getFullYear() - YEARS;
			let result: number[] = [];
			for(let i = 0; i <= YEARS + 10; i++) result.push(start + i);
			return result;
		}

		public get startDate(): Date {
			let result = this.displayDate.clone();
			result.setDate(1);
			result = result.addDays(-result.getDay());
			return result;
		}

		public get offset(): number {
			var test = this.displayDate.clone();
			test.setDate(1);
			return test.getDay();
		}

		public get days(): number {
			var test = this.displayDate.clone();
			test.setMonth(test.getMonth() + 1, 0);
			return test.getDate();
		}

		public get dateAt(): Date[] {
			var result: Date[] = [];
			for(let i = 0; i < 42; i++) {
				result.push(this.startDate.clone().addDays(i));
			}
			return result;
		}

		public classAt(pos: number): string {
			const date = this.dateAt[pos];
			let result = date.getMonth() == this.displayDate.getMonth() ? "" : "text-muted bg-muted";
			if(this.value && date.toDateString() == this.value.toDateString()) result += " selected";
			if(this.isDisabled(date)) result += " disabled";
			return result;
		}

		public isDisabled(date: Date): boolean {
			if(!this.other) return false;
			if(this.isStart) return date.datePart() > this.other.datePart();
			else return date.datePart() < this.other.datePart();
		}

		public labelAt(pos: number): string {
			return this.dateAt[pos].getDate().toString();
		}

		public move(m: number): void {
			this.displayDate.setMonth(this.displayDate.getMonth() + m);
			this.displayDate = this.displayDate.clone(); // 觸發變化
		}

		public setYear(y: number): void {
			this.displayDate.setFullYear(y);
			this.displayDate = this.displayDate.clone(); // 觸發變化
		}

		public setMonth(m: number): void {
			this.displayDate.setMonth(m - 1);
			this.displayDate = this.displayDate.clone(); // 觸發變化
		}

		public select(pos: number): void {
			const date = this.dateAt[pos];
			if(this.isDisabled(date)) return;
			this.displayDate = date.clone();
			this.$emit("input", date);
		}

		public spanClassAt(pos: number): string {
			if(!this.value || !this.other) return "";
			const [a, b] = [this.other.datePart().getTime(), this.value.datePart().getTime()].sort();
			const date = this.dateAt[pos].datePart().getTime();
			if(a <= date && date <= b && this.dateAt[pos].toDateString() != this.value.toDateString()) return "range";
		}

		public get date(): string {
			return this.value?.date() ?? "";
		}

		public get time(): string {
			return this.value?.time() ?? "";
		}

		public dateChange(): boolean {
			const timestamp = Date.parse(this.tempDate);
			if(!isNaN(timestamp)) {
				const date = new Date(timestamp);
				const temp = this.value?.clone() ?? date;
				temp.setFullYear(date.getFullYear(), date.getMonth(), date.getDate());
				if(!this.other || (this.isStart && temp < this.other || !this.isStart && this.other < temp)) {
					this.$emit('input', temp);
					return true;
				}
			}
			return false;
		}

		public timeChange(): boolean {
			const timestamp = Date.parse("2000-01-01T" + this.tempTime);
			if(!isNaN(timestamp)) {
				const date = new Date(timestamp);
				const temp = this.value?.clone() ?? new Date();
				temp.setHours(date.getHours(), date.getMinutes(), 0, 0);
				if(!this.other || (this.isStart && temp < this.other || !this.isStart && this.other < temp)) {
					this.$emit('input', temp);
					return true;
				}
			}
			return false;
		}
	}
