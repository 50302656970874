import type { RouteConfig } from 'vue-router';

/////////////////////////////////////
// 裝置管理

const routes: Array<RouteConfig> = [

	{
		path: '/device/add',
		component: () => import(/* webpackChunkName: "device" */ '@/views/device/Add.vue'),
	},
	{
		path: '/device/tag',
		component: () => import(/* webpackChunkName: "device" */ '@/views/device/Tag.vue'),
	},
	{
		path: '/device/share/:id',
		props: true,
		component: () => import(/* webpackChunkName: "device" */ '@/views/device/share/Index.vue'),
	},
	{
		path: '/device/share/link/:id',
		props: true,
		component: () => import(/* webpackChunkName: "device" */ '@/views/device/share/Link.vue'),
	},
	{
		path: '/device/share/accept/:id',
		props: true,
		component: () => import(/* webpackChunkName: "device" */ '@/views/device/share/Accept.vue'),
	},
];

export default routes;
