
	import { Component, Prop, Vue, Watch } from "vue-property-decorator";

	import { today } from "@common/date";
	import DateTimeRangePicker from "./dateTimeRangePicker/DateTimeRangePicker.vue";

	@Component({ components: { DateTimeRangePicker } })
	export default class extends Vue {
		$refs!: {
			picker: DateTimeRangePicker;
		};

		@Prop(Date) public max?: Date;
		@Prop(Date) public min?: Date;
		@Prop() public init?: number;

		public active: number = 0;
		protected value: Date[] = [];

		protected get end(): Date | undefined {
			return this.value[1];
		}

		@Watch("end") onEndChange(v: Date | undefined): void {
			if(v && v.getHours() == 0 && v.getMinutes() == 0 && v.getSeconds() == 0) {
				// eslint-disable-next-line @typescript-eslint/no-magic-numbers
				v.setHours(23, 59, 59, 999);
			}
		}

		created(): void {
			this.reset(this.init);
		}

		public reset(init?: number): void {
			if(init !== undefined) this.active = init;
		}

		protected async openDialog(): Promise<void> {
			const active = this.active;
			const value = this.value.concat();
			this.active = 4;
			if(await this.$refs.picker.show()) {
				this.$emit('change', this.value);
			} else {
				this.active = active;
				this.value = value;
			}
		}

		protected get filter(): Function {
			let now = new Date();
			let max = this.max ? this.max : now;
			if(max < now) max = now;
			let min = this.min ? this.min : null;
			if(min) {
				min = new Date(min.getTime());
				min.setHours(0, 0, 0, 0);
			}
			return (date: Date) => max && date > max || min && date < min;
		}

		protected set(active: number, days: number): void {
			this.active = active;
			const tomorrow = today().addDays(1);
			const ago = tomorrow.clone().addDays(-days);
			tomorrow.addMilliseconds(-1);
			this.value = [ago, tomorrow];
			this.$emit('change', this.value);
		}
	}
