
import Vue from 'vue';

// 註冊全域元件
const requireComponent = require.context('.', true, /\.vue$/);
requireComponent.keys().forEach(fileName => {
	const componentConfig = requireComponent(fileName);
	const name = fileName.split('/').pop()!.replace(/\.vue$/, '');
	Vue.component(name, componentConfig.default || componentConfig);
});

export {};
