

	import { Component, Prop, Vue } from "vue-property-decorator";
	import type { ReportIndexModel } from "@/models/Report";

	@Component
	export default class extends Vue {
		@Prop() public data!: ReportIndexModel;

		protected detail(): void {
			let url = '/report/detail/' + this.data.Id;
			let end = this.data.EndTime;
			if(end) url += "/" + encodeURIComponent(end.toISOString());
			this.$router.push(url);
		}
	}
