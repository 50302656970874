declare global {
	type AlertType = 'warning' | 'success' | 'danger' | 'error';
	type AlertMethod = (text: string, title?: string, icon?: AlertType, btn?: string) => Promise<void>;
	type ConfirmMethod = (text: string, title?: string, icon?: AlertType, yes?: string, no?: string) => Promise<boolean>;
}

interface IDialogSettings {
	alert: AlertMethod;
	confirm: ConfirmMethod;
}

const dialogs: IDialogSettings = {
	alert: (..._) => Promise.resolve(),
	confirm: (..._) => Promise.resolve(false),
};

export default dialogs;
