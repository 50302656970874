

	import { Component, Inject, Prop, Watch } from "vue-property-decorator";
	import Modal from "@/components/modal";

	import type { TagModel, MerchantKioskDetailModel } from "@common/models/kiosk";

	@Component
	export default class extends Modal {
		@Inject() public readonly alert!: AlertMethod;

		@Prop() public options!: TagModel[];

		public model: MerchantKioskDetailModel | null = null;
		protected tags: string[] = [];
		protected title: string = "";

		@Watch("model") onModel(v: MerchantKioskDetailModel): void {
			if(v) {
				this.title = v.Name || v.Kiosk.Name;
				this.tags = v.Tags.concat();
			}
		}

		public async submit(): Promise<void> {
			if(!this.model) return;
			let model = this.model;
			let old = model.Name || model.Kiosk.Name, oldTags = model.Tags;
			model.Name = this.title;
			model.Tags = this.tags;
			console.log(this.title);
			try {
				await post('/api/Device/Edit', {
					Id: model.Id,
					Name: this.title,
					Tags: this.tags.map(t => this.options.find(o => o.Name == t)!.Id),
				});
			} catch(e) {
				model.Name = old;
				model.Tags = oldTags;
				await this.alert('儲存失敗，請稍後再試', '', 'warning');
			}
			this.model = null;
		}

		protected btnClass(o: TagModel, i: number): string {
			return (this.tags.includes(o.Name) ? "btn-secondary" : "btn-light") + (i > 0 ? " ml-2" : "");
		}

		protected toggle(o: TagModel): void {
			let t = o.Name, i = this.tags.indexOf(t);
			if(i >= 0) this.tags.splice(i, 1);
			else this.tags.push(t);
		}
	}
