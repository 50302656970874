var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Component, Inject, InjectReactive, Prop, Vue } from "vue-property-decorator";
let default_1 = class default_1 extends Vue {
    constructor() {
        super(...arguments);
        this.selfSending = false;
    }
    get sending() {
        if (this.$attrs.type == "button")
            return this.selfSending;
        return this.formSending;
    }
    send() {
        var _a, _b, _c, _d;
        return __awaiter(this, void 0, void 0, function* () {
            if (this.$attrs.type != "button")
                return;
            this.selfSending = true;
            try {
                yield ((_b = (_a = this._events) === null || _a === void 0 ? void 0 : _a.click) === null || _b === void 0 ? void 0 : _b[0]());
            }
            catch (err) {
                if (err instanceof Error) {
                    console.dir(err);
                    yield this.alert(err.message, (_c = err.title) !== null && _c !== void 0 ? _c : this.errTitle, (_d = err.icon) !== null && _d !== void 0 ? _d : 'warning');
                }
            }
            this.selfSending = false;
        });
    }
};
__decorate([
    Inject()
], default_1.prototype, "alert", void 0);
__decorate([
    InjectReactive("_spinnerSending")
], default_1.prototype, "formSending", void 0);
__decorate([
    Prop(Boolean)
], default_1.prototype, "disabled", void 0);
__decorate([
    Prop(String)
], default_1.prototype, "errTitle", void 0);
default_1 = __decorate([
    Component
], default_1);
export default default_1;
