
	import { Component, Provide, Vue } from "vue-property-decorator";

	import type Alert from "@common/components/dialog/Alert.vue";
	import type Confirm from "@common/components/dialog/Confirm.vue";
	import type Menu from "@common/components/Menu.vue";

	declare global {
		export const MobileApp: {
			onBackPress: Function;
		};
	}

	@Component
	export default class App extends Vue {
		$refs!: {
			alert: Alert,
			confirm: Confirm,
			menu: Menu,
			panel: HTMLDivElement,
			title: HTMLDivElement,
		};

		created(): void {
			const obp = MobileApp.onBackPress;
			MobileApp.onBackPress = () => this.onBackPress() || obp();
		}

		mounted(): void {
			this.$refs.menu.init(this.$refs.panel);
		}

		protected get version(): string {
			return VERSION;
		}

		@Provide() public alert(message: string, title?: string, icon?: string, btn?: string): Promise<void> {
			return this.$refs.alert.showIcon(message, title, icon, btn);
		}

		@Provide() public confirm(message: string, title?: string, icon?: string): Promise<boolean> {
			return this.$refs.confirm.showIcon(message, title, icon);
		}

		@Provide() public toggleMenu(): void {
			this.$refs.menu.toggle();
		}

		@Provide() public back(): boolean {
			const DELAY = 10;
			if(this.$router.currentRoute.meta?.root) return false;

			let href = location.href;
			this.$router.back();
			setTimeout(() => {
				// 如果延遲之後網址沒有改變，就表示歷史無法繼續返回，此時就直接跳首頁
				if(location.href == href) this.$router.replace('/');
			}, DELAY);
			return true;
		}

		public onBackPress(): boolean {
			if(this.$refs.menu.closeIfOpen()) return true;
			return this.back();
		}

		protected afterLeave(): void {
			window.dispatchEvent(new Event('routertransition'));
		}
	}
