import type { RouteConfig } from 'vue-router';

/////////////////////////////////////
// 裝置管理

const routes: Array<RouteConfig> = [

	{
		path: '/fund',
		component: () => import(/* webpackChunkName: "fund" */ '@/views/fund/Index.vue'),
		meta: {
			root: true,
		},
	},
	{
		path: '/fund/history',
		component: () => import(/* webpackChunkName: "fund" */ '@/views/fund/History.vue'),
	},
];

export default routes;
