import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class extends Vue {
	@Prop(String) public title!: string;

	protected get formatTitle(): string {
		return this.title?.replace(/\n/g, "<br>");
	}
}
