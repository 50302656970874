var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
let HelloWorld = class HelloWorld extends Vue {
    constructor() {
        super(...arguments);
        this.disabled = false;
    }
    onEnableChanged(value) {
        if (value)
            this.start();
        else
            this.end();
    }
    get enabled() {
        return !this.$store.state.hideNav && !this.$store.state.hideMenu;
    }
    init(panel) {
        this.panel = panel;
        this.start();
        if (!this.enabled)
            this.end();
        let el = this.$el;
        el.style.display = "block";
        el.addEventListener("click", e => {
            if (e.target instanceof HTMLAnchorElement && this.slideout)
                this.slideout.close();
        });
    }
    start() {
        this.slideout = new Slideout({
            panel: this.panel,
            menu: this.$el,
            itemToMove: "menu",
            side: "left",
            grabWidth: 25,
        });
    }
    end() {
        if (!this.slideout)
            return;
        this.slideout.destroy();
        this.slideout = null;
        this.panel.removeChild(this.panel.firstChild);
    }
    toggle() {
        if (this.slideout)
            this.slideout.toggle();
    }
    closeIfOpen() {
        if (!this.slideout || !this.slideout.isOpen())
            return false;
        this.slideout.close();
        return true;
    }
};
__decorate([
    Prop(Boolean)
], HelloWorld.prototype, "disabled", void 0);
__decorate([
    Watch("enabled")
], HelloWorld.prototype, "onEnableChanged", null);
HelloWorld = __decorate([
    Component
], HelloWorld);
export default HelloWorld;
